import { createContext, useContext, useMemo, useState } from 'react'

const noop = () => {}

export type SedContextValue = {
  argumentsString: string
  inputFileString: string
  setInputFileString: (value: string) => void
  setArgumentsString: (value: string) => void
  setInputAndArguments: (values: { input: string; args: string }) => void
}

export const useSedContext = () => {
  const value = useContext(SedContext)

  return value
}

const SedContext = createContext<SedContextValue>({
  argumentsString: '',
  inputFileString: '',
  setInputFileString: noop,
  setArgumentsString: noop,
  setInputAndArguments: noop,
})

export default SedContext
