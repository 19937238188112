function SedAbout() {
  return (
    <>
      <p>
        Seddy uses{' '}
        <a
          href="https://www.gnu.org/software/sed/"
          target="_blank"
          rel="noreferrer"
        >
          GNU sed
        </a>
        , compiled from source to WebAssembly. Try <code>sed --version</code> to
        see what{`'`}s running.
      </p>
      <p>
        View the source code (and contribute!) on{' '}
        <a
          href="https://github.com/malwilley/seddy"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
        .
      </p>
      <p>
        See my other work at{' '}
        <a href="https://malachi.dev" target="_blank" rel="noreferrer">
          malachi.dev
        </a>
        .
      </p>
    </>
  )
}

export default SedAbout
