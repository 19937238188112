import { ReactNode } from 'react'
import styles from './SedHelp.module.scss'

const flags: Array<{
  flag: ReactNode
  description: ReactNode
}> = [
  {
    flag: (
      <>
        <div>
          <code>-E</code>
        </div>
        <div>
          <code>-r</code>
        </div>
      </>
    ),
    description: (
      <>
        Use{' '}
        <span className={styles.extended}>extended regular expressions</span> (
        <a
          href="https://www.gnu.org/software/sed/manual/sed.html#ERE-syntax"
          target="_blank"
          rel="noreferrer"
        >
          see docs
        </a>
        )
      </>
    ),
  },
  {
    flag: <code>-i</code>,
    description: 'edit file in place',
  },
  {
    flag: <code>-n</code>,
    description: 'suppress output',
  },
]

const syntax: Array<{ metacharacter: ReactNode; description: string }> = [
  {
    metacharacter: <code>.</code>,
    description: 'Any character',
  },
  {
    metacharacter: <code>[ ]</code>,
    description: `Any character contained in the brackets`,
  },
  {
    metacharacter: <code>[^ ]</code>,
    description: `Any character not contained in the brackets`,
  },
  {
    metacharacter: <code>^</code>,
    description: 'Starting position',
  },
  {
    metacharacter: <code>$</code>,
    description: 'Ending position',
  },
  {
    metacharacter: <code>*</code>,
    description: 'Matches the preceding element zero or more times',
  },
  {
    metacharacter: (
      <>
        <div>
          <code>{'\\{m\\}'}</code>
        </div>
        <div>
          <code
            className={styles.extended}
            title="Extended Regular Expressions (-e)"
          >
            {'{m}'}
          </code>
        </div>
      </>
    ),
    description: 'Matches the preceding element exactly m times',
  },
  {
    metacharacter: (
      <>
        <div>
          <code>{'\\{m,\\}'}</code>
        </div>
        <div>
          <code
            className={styles.extended}
            title="Extended Regular Expressions"
          >
            {'{m,}'}
          </code>
        </div>
      </>
    ),
    description: 'Matches the preceding element at least m times',
  },
  {
    metacharacter: (
      <>
        <div>
          <code>{'\\{m,n\\}'}</code>
        </div>
        <div>
          <code
            className={styles.extended}
            title="Extended Regular Expressions"
          >
            {'{m,n}'}
          </code>
        </div>
      </>
    ),
    description:
      'Matches the preceding element at least m and not more than n times',
  },
  {
    metacharacter: (
      <>
        <div>
          <code>{'\\( \\)'}</code>
        </div>
        <div>
          <code
            className={styles.extended}
            title="Extended Regular Expressions"
          >
            {'( )'}
          </code>
        </div>
      </>
    ),
    description: 'Defines a subexpression to be treated as a single element',
  },
]

const characterClasses: Array<{
  characterClass: ReactNode
  description: string
}> = [
  {
    characterClass: <code>[:upper:]</code>,
    description: 'uppercase letters',
  },
  {
    characterClass: <code>[:lower:]</code>,
    description: 'lowercase letters ',
  },
  {
    characterClass: <code>[:alpha:]</code>,
    description: 'upper- and lowercase letters ',
  },
  {
    characterClass: <code>[:digit:]</code>,
    description: 'digits',
  },
  {
    characterClass: <code>[:xdigit:]</code>,
    description: 'hexadecimal digits',
  },
  {
    characterClass: <code>[:alnum:]</code>,
    description: 'digits, upper- and lowercase letters',
  },
  {
    characterClass: <code>[:punct:]</code>,
    description: 'punctuation characters',
  },
  {
    characterClass: <code>[:blank:]</code>,
    description: 'space and TAB characters only',
  },
  {
    characterClass: <code>[:space:]</code>,
    description: 'blank (whitespace) characters',
  },
  {
    characterClass: <code>[:cntrl:]</code>,
    description: 'control characters ',
  },
  {
    characterClass: <code>[:graph:]</code>,
    description:
      'graphic characters (all characters which have graphic representation)',
  },
  {
    characterClass: <code>[:print:]</code>,
    description: 'graphic characters and space',
  },
]

function SedAbout() {
  return (
    <>
      <p>
        Sed is a stream editor. It reads a stream of input and writes a stream
        of output. This tool provides a STDIN and STDOUT for previewing
        transformations.
      </p>
      <p>
        <code>sed OPTIONS [SCRIPT] [INPUTFILE...]</code>
      </p>
      <p className={styles.heading}>options</p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>flag</th>
            <th>description</th>
          </tr>
        </thead>
        <tbody>
          {flags.map(({ flag, description }, i) => (
            <tr key={i}>
              <td>{flag}</td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className={styles.heading}>script syntax</p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>character</th>
            <th>description</th>
          </tr>
        </thead>
        <tbody>
          {syntax.map(({ metacharacter, description }, i) => (
            <tr key={i}>
              <td>{metacharacter}</td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className={styles.heading}>character classes</p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>class</th>
            <th>description</th>
          </tr>
        </thead>
        <tbody>
          {characterClasses.map(({ characterClass, description }, i) => (
            <tr key={i}>
              <td>{characterClass}</td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default SedAbout
