import clsx from 'clsx'
import { ReactNode, useState } from 'react'
import SedAbout from './SedAbout'
import SedExamples from './SedExamples'
import SedHelp from './SedHelp'
import styles from './Sidebar.module.scss'

type TabButtonProps = {
  id: string
  label: string
  type: TabButtonType
  selectedType: TabButtonType
  controls: string
  setSelectedType: (type: TabButtonType) => void
}

type TabPanelProps = {
  id: string
  children: ReactNode
  type: TabButtonType
  selectedType: TabButtonType
  labelledBy: string
}

type TabButtonConfig = {
  type: TabButtonType
  label: string
  tabButtonId: string
  tabPanelId: string
  content: ReactNode
}

type TabButtonType = 'help' | 'examples' | 'about'

function TabButton({
  id,
  label,
  type,
  selectedType,
  setSelectedType,
  controls,
}: TabButtonProps) {
  const selected = type === selectedType

  return (
    <button
      id={id}
      className={clsx(styles.tabButton, selected && styles.selected)}
      role="tab"
      aria-selected={selected}
      tabIndex={selected ? undefined : -1}
      aria-controls={controls}
      onClick={() => setSelectedType(type)}
    >
      {label}
    </button>
  )
}

function TabPanel({
  children,
  type,
  selectedType,
  labelledBy,
  id,
}: TabPanelProps) {
  const selected = type === selectedType

  return (
    <div
      id={id}
      tabIndex={0}
      role="tabpanel"
      className={clsx(styles.tabPanel, !selected && styles.hidden)}
      aria-labelledby={labelledBy}
    >
      {children}
    </div>
  )
}

const tabs: TabButtonConfig[] = [
  {
    type: 'help',
    label: 'help',
    tabButtonId: 'help-tab',
    tabPanelId: 'help-panel',
    content: <SedHelp />,
  },
  {
    type: 'examples',
    label: 'examples',
    tabButtonId: 'examples-tab',
    tabPanelId: 'examples-panel',
    content: <SedExamples />,
  },
  {
    type: 'about',
    label: 'about',
    tabButtonId: 'about-tab',
    tabPanelId: 'about-panel',
    content: <SedAbout />,
  },
]

function Sidebar() {
  const [selectedType, setSelectedType] = useState<TabButtonType>('help')

  return (
    <aside className={styles.main}>
      <div className={styles.header} role="tablist" aria-label="Menu">
        {tabs.map(({ type, label, tabButtonId, tabPanelId }) => (
          <TabButton
            id={tabButtonId}
            label={label}
            type={type}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            controls={tabPanelId}
            key={type}
          />
        ))}
      </div>
      {tabs.map(({ type, tabButtonId, tabPanelId, content }) => (
        <TabPanel
          id={tabPanelId}
          labelledBy={tabButtonId}
          type={type}
          selectedType={selectedType}
          key={type}
        >
          {content}
        </TabPanel>
      ))}
    </aside>
  )
}

export default Sidebar
