import { useCallback, useState, useRef, useEffect } from 'react'
import {
  RunFunction,
  SedWorkerIncomingMessage,
  SedWorkerOutgoingMessage,
} from '../../types'

const useSed = () => {
  const workerRef = useRef<Worker | null>(null)
  const [isRunning, setIsRunning] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [output, setOutput] = useState('')
  const [lastRunWasError, setLastRunWasError] = useState(false)

  useEffect(() => {
    return () => {
      workerRef.current?.terminate()
    }
  }, [])

  const run: RunFunction = useCallback(async (args, input) => {
    setIsRunning(true)
    setOutput('')

    if (workerRef.current) {
      workerRef.current.terminate()
    }

    workerRef.current = new Worker(new URL('../../sed.worker', import.meta.url))
    workerRef.current.onmessage = (
      event: MessageEvent<SedWorkerOutgoingMessage>,
    ) => {
      switch (event.data.type) {
        case 'print': {
          const line = event.data.text + '\n'
          setOutput((oldText) => oldText + line)
          break
        }
        case 'initialized': {
          setIsInitialized(true)
          break
        }
        case 'return': {
          setIsRunning(false)
          setLastRunWasError(event.data.code !== 0)
          workerRef.current?.terminate()
          break
        }
      }
    }

    workerRef.current.postMessage({
      type: 'run',
      args,
      input,
    } as SedWorkerIncomingMessage)
  }, [])

  return {
    run,
    output,
    lastRunWasError,
    isInitialized,
    isRunning,
  }
}

export default useSed
