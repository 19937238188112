import { useEffect, useMemo, useState } from 'react'
import useSed from './hooks/useSed'
import { RunFunction } from '@modules/sed/types'
import styles from './SedTester.module.scss'
import ArgumentsInput from '@common/components/ArgumentsInput'
import Sidebar from './Sidebar'
import SedContext, {
  SedContextValue,
  useSedContext,
} from './context/SedContext'
import { exampleText, sedExamples } from './SedExamples'
import SedInputArea from './SedInputArea'
import SedOutputArea from './SedOutputArea'

/**
 * Runs sed when arguments or input changes and is debounced on both inputs
 */
const useDebouncedSedRun = ({
  run,
  delay,
  argumentsString,
  inputFileString,
}: {
  run: RunFunction
  delay: number
  argumentsString: string
  inputFileString: string
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      run(argumentsString, inputFileString)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [argumentsString, inputFileString, delay, run])
}

export const useSedContextValue = (): SedContextValue => {
  const [argumentsString, setArgumentsString] = useState(
    sedExamples[0].children[0].args,
  )
  const [inputFileString, setInputFileString] = useState(exampleText)

  return useMemo(
    () => ({
      argumentsString,
      inputFileString,
      setArgumentsString,
      setInputFileString,
      setInputAndArguments: ({ input, args }) => {
        setInputFileString(input)
        setArgumentsString(args)
      },
    }),
    [argumentsString, inputFileString],
  )
}

function SedInputsGrid() {
  const { isInitialized, run, output, lastRunWasError, isRunning } = useSed()
  const {
    argumentsString,
    inputFileString,
    setArgumentsString,
    setInputFileString,
  } = useSedContext()

  useDebouncedSedRun({ run, delay: 300, argumentsString, inputFileString })

  return (
    <>
      <ArgumentsInput
        disabled={!isInitialized}
        handleChange={setArgumentsString}
        value={argumentsString}
        lastRunWasError={lastRunWasError}
      />
      <SedInputArea
        disabled={!isInitialized}
        handleChange={setInputFileString}
        value={inputFileString}
      />
      <SedOutputArea
        disabled={!isInitialized}
        handleChange={() => null}
        value={output}
        isRunning={isRunning}
      />
    </>
  )
}

function SedTester() {
  const value = useSedContextValue()

  return (
    <SedContext.Provider value={value}>
      <div className={styles.inputsGrid}>
        <Sidebar />
        <SedInputsGrid />
      </div>
    </SedContext.Provider>
  )
}

export default SedTester
