import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import styles from './TextArea.module.scss'

type TextAreaProps = PropsWithChildren<{
  disabled: boolean
  value: string
  handleChange: (value: string) => void
  id: string
  readOnly?: boolean
}>

type TextAreaHeaderProps = PropsWithChildren<{
  className?: string
}>

function Header({ className, children }: TextAreaHeaderProps) {
  return <div className={clsx(styles.header, className)}>{children}</div>
}

function TextArea({
  disabled,
  value,
  handleChange,
  id,
  children,
  readOnly,
}: TextAreaProps) {
  return (
    <div className={styles.container}>
      {children}
      <textarea
        id={id}
        disabled={disabled}
        className={styles.textarea}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        spellCheck={false}
        readOnly={readOnly}
      />
    </div>
  )
}

TextArea.Header = Header

export default TextArea
