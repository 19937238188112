import TextArea from '@common/components/TextArea'

type TextAreaProps = {
  disabled: boolean
  value: string
  handleChange: (value: string) => void
}

function SedInputArea({ disabled, value, handleChange }: TextAreaProps) {
  return (
    <TextArea id="input-textarea" {...{ disabled, value, handleChange }}>
      <TextArea.Header>
        <label htmlFor="input-textarea">input</label>
      </TextArea.Header>
    </TextArea>
  )
}

export default SedInputArea
