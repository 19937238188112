import { useEffect, useRef } from 'react'
import styles from './ArgumentsInput.module.scss'
import clsx from 'clsx'

type ArgumentsInputProps = {
  disabled: boolean
  value: string
  handleChange: (value: string) => void
  lastRunWasError: boolean
}

function ArgumentsInput({
  disabled,
  value,
  handleChange,
  lastRunWasError,
}: ArgumentsInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!disabled) {
      inputRef?.current?.focus()
    }
  }, [disabled])

  return (
    <div className={styles.main}>
      <div aria-hidden className={styles.prefix}>
        <span
          className={clsx(
            styles.prompt,
            lastRunWasError ? styles.error : styles.success,
          )}
        >
          ❱
        </span>
        sed
      </div>
      <input
        aria-label="sed arguments"
        ref={inputRef}
        className={styles.input}
        disabled={disabled}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
      />
    </div>
  )
}

export default ArgumentsInput
