import TextArea from '@common/components/TextArea'
import Spinner from '@common/components/Spinner'
import styles from './SedOutputArea.module.scss'

type SedOutputAreaProps = {
  disabled: boolean
  value: string
  handleChange: (value: string) => void
  isRunning: boolean
}

type HeaderProps = {
  isRunning: boolean
}

function Header({ isRunning }: HeaderProps) {
  return (
    <TextArea.Header className={styles.header}>
      <div className={styles.spinner}>{isRunning && <Spinner />}</div>
      <label className={styles.label} htmlFor="output-textarea">
        output
      </label>
      <div />
    </TextArea.Header>
  )
}

function SedOutputArea({
  disabled,
  value,
  handleChange,
  isRunning,
}: SedOutputAreaProps) {
  return (
    <TextArea
      id="output-textarea"
      readOnly
      {...{ disabled, value, handleChange }}
    >
      <Header isRunning={isRunning} />
    </TextArea>
  )
}

export default SedOutputArea
