import type { NextPage } from 'next'
import Head from 'next/head'
import PageLayout from '@common/components/PageLayout'
import SedTester from '@modules/sed/components/SedTester'

const Home: NextPage = () => {
  return (
    <PageLayout>
      <Head>
        <title>Seddy - test sed in the browser</title>
      </Head>
      <SedTester />
    </PageLayout>
  )
}

export default Home
