import { useSedContext } from './context/SedContext'
import styles from './SedExamples.module.scss'

export const exampleText = `Body Type,Name,Radius (km),Distance from Sun (km)
Star,Sol,1391400,-
Planet,Mercury,4879,57900000
Planet,Venus,12104,108200000
Planet,Earth,12756,149600000
Planet,Mars,6792,227900000
Planet,Jupiter,142984,778600000
Planet,Saturn,120536,1433500000
Planet,Uranus,51118,2872500000
Planet,Neptune,49528,4495100000`

export const sedExamples = [
  {
    sectionName: 'substitution',
    children: [
      {
        name: 'simple find/replace',
        args: "'s/Planet/Big rock/g'",
        input: exampleText,
      },
      {
        name: 'find/replace with capture group',
        args: "-r 's/([[:digit:]]+)/\\1km/g'",
        input: exampleText,
      },
    ],
  },
  {
    sectionName: 'appending',
    children: [
      {
        name: 'add a new line',
        args: "'$ aDwarf Planet,Pluto,1188,7370000000'",
        input: exampleText,
      },
      {
        name: 'duplicate a line',
        args: "'/Earth/p'",
        input: exampleText,
      },
    ],
  },
  {
    sectionName: 'filtering',
    children: [
      {
        name: 'remove lines containing match',
        args: "'/Planet/d'",
        input: exampleText,
      },
      {
        name: 'remove lines after match',
        args: "'/Earth/q'",
        input: exampleText,
      },
    ],
  },
  {
    sectionName: 'line numbers',
    children: [
      {
        name: 'print line 2',
        args: "-n '2p'",
        input: exampleText,
      },
      {
        name: 'skip line 2',
        args: "'2d'",
        input: exampleText,
      },
      {
        name: 'print lines 3 through 5',
        args: "-n '3,5p'",
        input: exampleText,
      },
      {
        name: 'print every other line',
        args: "-n '1~2p'",
        input: exampleText,
      },
    ],
  },
  {
    sectionName: 'escaping characters',
    children: [
      {
        name: 'escape forward slashes',
        args: "'s_com/index_org/home_'",
        input: 'http://www.example.com/index.html',
      },
    ],
  },
]

function SedExamples() {
  const { setInputAndArguments } = useSedContext()

  return (
    <>
      <ul className={styles.sectionList}>
        {sedExamples.map(({ sectionName, children }) => (
          <li key={sectionName}>
            <div>{sectionName}</div>
            <ul className={styles.list}>
              {children.map(({ name, args, input }) => (
                <li className={styles.item} key={name}>
                  <button
                    className={styles.exampleButton}
                    onClick={() => setInputAndArguments({ input, args })}
                  >
                    {name}
                  </button>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  )
}

export default SedExamples
