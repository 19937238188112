import { useEffect, useRef, useState } from 'react'
import styles from './Spinner.module.scss'

const states = ['|', '/', '—', '\\']
const numStates = states.length

const ms = 200

function Spinner() {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((oldIndex) => {
        return (oldIndex + 1) % numStates
      })
    }, ms / numStates)

    return () => {
      clearTimeout(timer)
    }
  }, [index])

  return (
    <div className={styles.main} aria-hidden>
      {states[index]}
    </div>
  )
}

export default Spinner
